import dynamic from 'next/dynamic';

export const InsightsSection = dynamic(() => import('./InsightsSection'));
export const InsightsHomepageSection = dynamic(
  () => import('./InsightsHomepageSection'),
);
export const ExchangeSection = dynamic(() => import('./ExchangeSection'));
export const KnowledgePlaylistSection = dynamic(
  () => import('./KnowledgePlaylistSection'),
);
export const KnowledgeSeriesSection = dynamic(
  () => import('./KnowledgeSeriesSection'),
);
export const ListBlog = dynamic(() => import('./ListBlog'));
export const NewsSection = dynamic(() => import('./NewsSection'));
export const ListNewsCategory = dynamic(() => import('./ListNewsCategory'));
export const OtherInsightSection = dynamic(
  () => import('./OtherInsightSection'),
);
